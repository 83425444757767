import { PageProps } from "gatsby";
import React from "react";

import { Faq } from "../../components/pages/Faq";

import { dnProps } from "../../js/utils";

const FaqPage = (props: PageProps) => <Faq {...{ ...dnProps, ...props }} />;

export default FaqPage;
